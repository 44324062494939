var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "paid-detail-component" }, [
    _c("div", { staticClass: "paid-name" }, [_vm._v("1.基本信息")]),
    _c("div", { staticClass: "paid-arrange" }, [
      _c("div", { staticClass: "paid-arrange-setWidth paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("内容记录")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.content)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("项目类型")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.projectName)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("所属公司")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.enterpriseDescName)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("联系人")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.contactPerson)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("联系电话")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.contactPhone)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("经办客服")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.ordPersonName)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("问题类型")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(_vm._s(_vm.data.issueType)),
        ]),
      ]),
      _c("div", { staticClass: "paid-arrange-sort" }, [
        _c("div", { staticClass: "title" }, [_vm._v("事件类型")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.data.isOrderEvent == "Y" ? "工单事件" : "非工单事件") +
              "\n      "
          ),
        ]),
      ]),
      _vm.data.isOrderEvent == "Y"
        ? _c("div", { staticClass: "paid-arrange-sort" }, [
            _c("div", { staticClass: "title" }, [_vm._v("工单模板")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.data.orderTemplateName) + "\n      "
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _c("div", { staticClass: "paid-name" }, [_vm._v("2.处理结果")]),
    _vm.data.isOrderEvent == "N"
      ? _c(
          "div",
          [
            _c(
              "el-form",
              {
                ref: "ruleForm",
                staticClass: "demo-ruleForm",
                attrs: { model: _vm.ruleForm, rules: _vm.rules },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "处理人", prop: "name" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isResults },
                              model: {
                                value: _vm.ruleForm.name,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "name", $$v)
                                },
                                expression: "ruleForm.name",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "处理部门", prop: "department" } },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isResults },
                              model: {
                                value: _vm.ruleForm.department,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "department", $$v)
                                },
                                expression: "ruleForm.department",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "处理时间", prop: "processTime" } },
                          [
                            _c("el-date-picker", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: _vm.isResults,
                                type: "datetime",
                                "value-format": "timestamp",
                                placeholder: "选择处理时间",
                              },
                              model: {
                                value: _vm.ruleForm.processTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "processTime", $$v)
                                },
                                expression: "ruleForm.processTime",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 5 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "处理结果",
                              prop: "processResults",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { disabled: _vm.isResults },
                              model: {
                                value: _vm.ruleForm.processResults,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "processResults", $$v)
                                },
                                expression: "ruleForm.processResults",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                !_vm.isResults
                  ? _c(
                      "el-row",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { align: "right" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitForm("ruleForm")
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", { staticClass: "paid-arrange" }, [
          _c("div", { staticClass: "paid-arrange-sort" }, [
            _c("div", { staticClass: "title" }, [_vm._v("责任部门")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.data.orderClientVO.resDeptName)),
            ]),
          ]),
          _c("div", { staticClass: "paid-arrange-sort" }, [
            _c("div", { staticClass: "title" }, [_vm._v("开始执行时间")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(_vm._s(_vm.data.orderClientVO.startDate || "-")),
            ]),
          ]),
          _c("div", { staticClass: "paid-arrange-sort" }, [
            _c("div", { staticClass: "title" }, [_vm._v("工单执行人")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.data.orderClientVO.handlePersonName || "-") +
                  "\n      "
              ),
            ]),
          ]),
          _c("div", { staticClass: "paid-arrange-sort" }, [
            _c("div", { staticClass: "title" }, [_vm._v("执行人电话")]),
            _c("div", { staticClass: "content" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.data.orderClientVO.handlePersonPhone || "-") +
                  "\n      "
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "workOrder", on: { click: _vm.jumpToRwork } },
            [_vm._v("查看工单详情")]
          ),
        ]),
    _vm.data.reviewStatus != 3
      ? _c("div", [
          _c("div", { staticClass: "paid-name" }, [_vm._v("3.回访满意度")]),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "followUp",
                  attrs: {
                    model: _vm.followUp,
                    rules: _vm.rulefollow,
                    "label-position": "top",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "处理情况", prop: "situation" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: {
                                    size: "medium",
                                    disabled: _vm.isfollowUp,
                                  },
                                  model: {
                                    value: _vm.followUp.situation,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.followUp, "situation", $$v)
                                    },
                                    expression: "followUp.situation",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { border: "", label: 1 } },
                                    [_vm._v("不满意")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { border: "", label: 2 } },
                                    [_vm._v("基本不满意")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { border: "", label: 3 } },
                                    [_vm._v("很满意")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              staticClass: "auto-height-item",
                              attrs: {
                                label: "内容记录",
                                prop: "contentRecord",
                              },
                            },
                            [
                              _c("el-input", {
                                staticStyle: { width: "30%" },
                                attrs: {
                                  disabled: _vm.isfollowUp,
                                  type: "textarea",
                                  rows: 5,
                                  maxlength: "250",
                                  placeholder: "请描述具体内容",
                                },
                                model: {
                                  value: _vm.followUp.contentRecord,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.followUp, "contentRecord", $$v)
                                  },
                                  expression: "followUp.contentRecord",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.data.reviewStatus == 1
                    ? _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { align: "right" } },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.updataFollow } },
                                [_vm._v("不回访")]
                              ),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.submitFollowForm("followUp")
                                    },
                                  },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }